body {
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  position: relative;
  background-color: #f2f2f2;
}

/* 
html {
  font-size: calc(0.6em + 1vw);
} */

input {
  width: 100%;
  max-width: 300px;
  font-weight: 700;
  font-size: 0.7em;
  background-color: white;
  color: black;
  line-height: normal;
  outline: none;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input::placeholder,
::placeholder,
::-webkit-input-placeholder {
  font-weight: 300;
  line-height: normal;
  font-family: "Source Sans Pro", sans-serif;
}
